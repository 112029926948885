import React, { useState, useEffect } from 'react';
import WinXP from 'WinXP';
import Intro from 'WinXP/apps/intro/intro';
import WindowLoadingIntro from 'WinXP/apps/intro/window-loading-intro';
import windowsStartupSound from './assets/windows-xp-audio.mp3';

const App = () => {
  const [startPressed, setStartPressed] = useState(false);
  const [showWinXP, setShowWinXP] = useState(false);

  const startSequence = () => {
    if (!startPressed) {
      setTimeout(() => {
        setStartPressed(true);
      }, 1000);
    }
  };

  useEffect(() => {
    const handleKeyPress = e => {
      startSequence();
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (startPressed) {
      const timer = setTimeout(() => {
        setShowWinXP(true);
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [startPressed]);

  useEffect(() => {
    if (showWinXP) {
      const audio = new Audio(windowsStartupSound);
      audio.play().catch(err => console.log('Audio playback failedd:', err));
    }
  }, [showWinXP]);

  return (
    <React.Fragment>
      {showWinXP ? (
        <WinXP />
      ) : startPressed ? (
        <WindowLoadingIntro />
      ) : (
        <div
          onClick={startSequence}
          style={{ cursor: 'pointer', height: '100vh' }}
        >
          <Intro />
        </div>
      )}
    </React.Fragment>
  );
};

export default App;
